<template>
    <div class="p-field">
        <h5>Postcode <field-required-notification /></h5>
        <div class="p-inputgroup">
            <InputText
                type="text"
                id="postcode"
                class="text-uppercase"
                name="postcode"
                :disabled="postcodesLoaded"
                v-model.trim="selected.postcode"
            />
            <Button
                v-show="!postcodesLoaded"
                icon="pi pi-search"
                :disabled="!postcodeInputted || postcodesLoaded"
                class="p-button-warning"
                :onclick="findPostcode"
            />

            <Button
                icon="pi pi-undo"
                :disabled="!postcodesLoaded"
                v-show="postcodesLoaded"
                class="p-button-secondary"
                :onclick="clearAddressData"
            />
            <ValidationText v-bind:errors="errors" field="postcode" />
        </div>
    </div>

    <div class="p-field" v-show="postcodesLoaded">
        <h5>Address Line 1 <field-required-notification /></h5>
        <Dropdown
            v-model="selected.address1"
            :options="addresses"
            @change="addressChange"
            style="width:100%;"
            placeholder="Select an Address Line"
        />
    </div>
</template>

<script lang="js">
import ValidationText from '@/components/helpers/ValidationText'
import Dropdown from 'primevue/dropdown'
import { formatPostcode, postcodeLookup } from '@/services/postcodeLookup'

export default {
    name: 'PostcodeLookup',
    components: {Dropdown, ValidationText},
    emits: ['addressSelected', 'addressCleared'],
    data: () => {
        return {
            selected : {
                postcode: '',
                address1: '',
            },
            showError: false,
            errors: [],
            loading: false,
            retrievedLocationData: [],
            addresses: [],

        }
    },
    props: {
    },
    computed: {
        postcodeInputted() {
            return (this.selected.postcode.length >=5) ? true : false
        },
        postcodesLoaded() {
            return this.retrievedLocationData.addresses && this.retrievedLocationData.addresses.length > 0 ? true : false
        }
    },
    methods: {
        async findPostcode() {
            try {
                this.errors = []
                this.retrievedLocationData = await postcodeLookup(this.selected.postcode)
                this.addresses = this.retrievedLocationData.addresses.map((record) => {
                    return record[0]
                })
                this.addressLineVisible = true
            } catch (error) {
               this.errors.push({field: 'postcode', message: 'Postcode not found'})
            }
        },
        addressChange() {

           const selected = this.selected.address1
            const address = this.retrievedLocationData.addresses.find(function(record) {
                return record[0] == selected
            })

            this.selected.address2 = address[1]
            this.selected.address3 = address[2]
            this.selected.address4 = address[3]
            this.selected.locality = address[4]
            this.selected.city = address[5]
            this.selected.county = address[6]

            this.$emit('addressSelected', {
                longitude: this.retrievedLocationData.longitude,
                latitude: this.retrievedLocationData.latitude,
                postcode: formatPostcode(this.selected.postcode),
                address1: this.selected.address1,
                address2:address[1],
                address3: address[2],
                address4: address[3],
                locality: address[4],
                city: address[5],
                county: address[6]
            })
        },
        clearAddressData() {
            this.selected.postcode = ''
            this.selected.address1 = ''

            this.retrievedLocationData = []
            this.addresses = []
            this.errors = []

            this.$emit('addressCleared')
        }

    }
}
</script>

<style scoped lang="scss">
.p-field {
    display: block;
    margin-top: 20px;
}
::v-deep(.p-dropdown) {
    .p-hidden-accessible {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .p-hidden-accessible input,
    .p-hidden-accessible select {
        transform: scale(0);
    }
}
</style>
