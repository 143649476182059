import axios from 'axios'

/*
Provide aditional functionality for postcode lookups
*/
export function formatPostcode(postcode: string) {
    return postcode
        .trim()
        .replace(/\s/g, '')
        .toUpperCase()
}

export async function postcodeLookup(postcode: string) {
    try {
        const formattedPostcode = formatPostcode(postcode)
        const response = await axios.get(
            '/v1/location/postcode/' + formattedPostcode
        )
        if (response?.data?.data[0]) {
            return response.data.data[0]
        }
        throw new Error(`No postcodes found for ${formattedPostcode}`)
    } catch (error) {
        return Promise.reject({ ...error })
    }
}
